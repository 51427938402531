@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Prompt:wght@100;200;300;400;500;600;700;800;900&family=Caramel&display=swap');

:root {
    --primaryFont: 'Poppins', sans-serif;
    --whiteColor: #FFFFFF;
    --primaryTextColor: #000000;
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    font-family: var(--primaryFont);
    background: var(--whiteColor);
    color: var(--primaryTextColor);
    font-size: 1rem;
    font-weight: 400;
}

a {
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    line-height: 160%;
    font-weight: 400;
}

ul,
ol {
    padding: 0;
    list-style: none;
    margin: 0;
    line-height: normal;
    font-weight: 400;
}

* {
    margin: 0;
    padding: 0;
}


.form-group label {
    color: #000000;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: normal;
    line-height: 200%;
    margin-bottom: 0;
    display: block;
}

.form-group + .form-group {
    margin-top: 0.5rem;
}

.form-control {
    border: 1px solid #D0D5DD;
    padding: 8px 12px;
    line-height: normal;
    height: auto;
    border-radius: 6px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .form-control::placeholder {
    color: #ABABAB;
    opacity: 1;
  }

  .form-control:focus {
    outline: none;
    box-shadow: none;
  }

  .rjsf {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .btn {
    background: #FED500;
    width: 100%;
    padding: 1rem 2rem;
    color: #000000;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border: 1px solid #FED500;
    display: inline-block;
  }

  .btn:hover {
    background: transparent;
    color: #000000;
    border-color: #FED500;
  }

  .required {
    color: red;
  }